import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store/store';
import  ThemeContext  from "./context/ThemeContext";
import {initializeApp} from "firebase/app";

const root = ReactDOM.createRoot(document.getElementById('root'));

initializeApp({
    apiKey: "AIzaSyB5UV2VE50BYcbrmAZNnqU3ywcaXIKh4Lw",
    authDomain: "mikro-a05bb.firebaseapp.com",
    databaseURL: "https://mikro-a05bb-default-rtdb.firebaseio.com",
    projectId: "mikro-a05bb",
    storageBucket: "mikro-a05bb.appspot.com",
    messagingSenderId: "778652639569",
    appId: "1:778652639569:web:bd9f293fa6374feab57370",
    measurementId: "G-CW81KD0VMS"
})

root.render(
  <React.StrictMode>
    <Provider store = {store}>
      <BrowserRouter basename='/'>
        <ThemeContext>
          <App />
        </ThemeContext>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
