import React from 'react';
import {useNavigate} from "react-router-dom";

const Bill = (props) => {
    const navigate = useNavigate();

    const payBill = (bill) => {
        bill[7] = bill[7].replace(",", "COMA-PARSER")
        navigate("/paybill?bill=" + bill)
    }

    const getDate = (milis) => {
        var fecha = new Date(milis);
        var año = fecha.getFullYear();
        var mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que se le suma 1
        var día = fecha.getDate();

        return `${día}/${mes}/${año}`;
    }

    return (
        <>
            <tr onClick={ () => payBill(props.data)}>
                <td><span>{props.data[0]}</span></td>
                <td><span>{props.data[1]}</span></td>
                <td><span>{props.data[7]}</span></td>
                <td><span>{props.data[8]}</span></td>
                <td><span>{props.data[9]}</span></td>
                <td><span>{props.data[3]}</span></td>
                <td><span>{ getDate(props.data[2]) }</span></td>
                <td><span>{ getDate(props.data[5]) }</span></td>
                <td><span>{props.data[4]}</span></td>
            </tr>
        </>
    );

}
export default Bill;
