import React, {useEffect, useState} from "react";
import {IP} from "../constans/Constants";

const ZoneReportItem = (data) => {

    const [userState, setUserState] = useState([]);

    const getDate = (milis) => {
        var fecha = new Date(milis);
        var año = fecha.getFullYear();
        var mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que se le suma 1
        var día = fecha.getDate();

        return `${día}/${mes}/${año}`;
    }

    useEffect(() => {
        fetch(IP + "usuario/" + data.data[11])
            .then(function(response) {
                if (response.ok) {
                    return response.json(); // Parsea la respuesta como JSON
                }
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(userData) {
                setUserState(userData.estado)
            })
            .catch(function(error) {
                console.log(error);
            });

    }, []);

    return (
        <tr style={{fontSize: "16px"}}>
            <td style={{padding: "10px"}}><span>{data.data[0]}</span></td>
            <td style={{padding: "10px"}}><span>{data.data[1]}</span></td>
            <td style={{padding: "10px"}}><span>{data.data[7]}</span></td>
            <td style={{padding: "10px"}}><span>{data.data[8]} {data.data[8] == "" ? "" : "/"} {data.data[9]}</span></td>
            <td style={{padding: "10px"}}><span>{ getDate(data.data[5]) }</span></td>
            <td style={{padding: "10px"}}><span>{data.data[4]}</span></td>
            <td style={{padding: "10px"}}><span>{userState}</span></td>
            <td style={{padding: "50px"}}><span></span></td>
        </tr>
    )
}

const ZoneReport = ({
                        zone,
                        bills
                    }) => {

    const [sysdoc, setSysdoc] = useState([]);

    const getDate = (milis) => {
        var fecha = new Date(milis);
        var año = fecha.getFullYear();
        var mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que se le suma 1
        var día = fecha.getDate();
        var hora = fecha.getHours()
        var minutos = fecha.getMinutes()


        return `${día}/${mes}/${año} - ${hora}:${minutos}`;
    }

    const LandscapeOrientation = () => (
        <style type="text/css">
            {"@media print{@page {size: landscape}}"}

        </style>
    );

    useEffect(() => {
        fetch(IP + "sysdoc/get")
            .then(function(response) {
                if (response.ok) {
                    return response.json(); // Parsea la respuesta como JSON
                }
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(sysdocData) {
                setSysdoc(sysdocData)
            })
            .catch(function(error) {
                console.log(error);
            });

    }, []);

    return (
        <div className="reporte-ciclistas">

            {LandscapeOrientation()}


            <div style={{fontSize: "14px", display: "inline-block"}}>
                <p style={{fontSize: "14px"}}><strong>TELCOM S.A DE C.V - Reporte de cuentas pendientes</strong></p>
                <div style={{display: "inline-block"}}>
                    <p>
                        {sysdoc.emirtn}<br/>
                        Terminal de Buses Cotol, contiguo ExpoNovedadesTv<br/>
                        (504) 2445-51771 / 9626-3013 / 9435-9140 / 94357816
                    </p>
                </div>
                <div style={{display: "inline-block", marginLeft: "50px"}}>
                    <p>
                        Zona: {zone}<br/>
                        Fecha de Impresion: {getDate(new Date().getMilliseconds())}
                    </p>
                </div>
            </div>


            <table style={{width: "100%", borderCollapse: "collapse"}} border="1">
                <thead>
                <tr style={{fontSize: "14px"}}>
                    <th>COD</th>
                    <th>NOMBRE DE CLIENTE</th>
                    <th>DIRECCION</th>
                    <th>TELEFON</th>
                    <th>VENCE</th>
                    <th style={{fontSize: "12px"}}>A PAGAR</th>
                    <th>ESTADO</th>
                    <th>NOTAS</th>
                </tr>
                </thead>
                <tbody>
                    {bills.map((data) => {
                        return (
                            <ZoneReportItem data={data}/>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default ZoneReport;
