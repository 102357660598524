import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

const Client = (props, index) => {
    const navigate = useNavigate();

    return (
        <tr key={index}>
            <td><span style={{fontSize: "10px"}}>{props.props.nombre}</span></td>
            <td><span style={{fontSize: "10px"}}>{props.props.cedula}</span></td>
            <td><span style={{fontSize: "10px"}}>{ props.props.estado == "ACTIVO" ? "ACT" : "RET" }</span></td>
            <td><span style={{fontSize: "10px"}}>{props.props.correo}</span></td>
            <td><span style={{fontSize: "10px"}}>{props.props.telefono} / {props.props.movil}</span></td>
            <td><span style={{fontSize: "10px"}}>{props.props.codigo}</span></td>
            <td><span style={{fontSize: "10px"}}>{props.props.clienteZona}</span></td>
            <td><span style={{fontSize: "10px"}}>{props.props.facturaTipo}</span></td>
        </tr>
    );

}
export default Client;