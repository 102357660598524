import React from "react";

const Footer = () => {
	var d = new Date();
	return (
		<div className="footer out-footer">
		</div>
	);
};

export default Footer;
