import React, {useContext, useEffect, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import {Card, Col, Nav, Tab} from "react-bootstrap";
import { Dropdown } from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import Bill from "./items/Bill";
import DatePicker from "react-datepicker";
import {IP} from "./constans/Constants";
import 'react-datepicker/dist/react-datepicker.css'
import ReactDOM from "react-dom";
import TicketToPrint from "./print/TicketToPrint";
import ZoneReport from "./print/ZoneReport";

const Home = () => {

	var container = document.createElement('div')

	const [zones, setZones] = useState([]);
	const [selectedNameZone, setSelectedNameZone] = useState("Seleccionar");
	const navigate = useNavigate();

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const [bills, setBills] = useState([]);

	const formatDate = (date) => {
		const day = date.getDate()
		const month = date.getMonth() + 1
		const year = date.getFullYear()

		const dayFormatted = day < 10 ? '0' + day : day
		const mothFormatted = month < 10 ? '0' + month : month

		return `${dayFormatted}-${mothFormatted}-${year}`
	}

	useEffect(() => {
		fetch(IP + "zona/")
			.then(function(response) {
				if (response.ok) {
					return response.json(); // Parsea la respuesta como JSON
				}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let zones = []
				for(let i in data.zonas){
					zones.push(Object.values(data.zonas[i]))
				}
				setZones(zones)
			})
			.catch(function(error) {
				console.log(error);
			});


	}, []);

	const verifyL = (bills) => {
		if (sessionStorage.getItem("email") == "info@comunicacioneshn.com") {
			let billsL = []
			for(let i in bills) {
				if (bills[i][12] == "Factura") {
					billsL.push(bills[i])
				}
			}
			return billsL
		} else {
			return bills
		}
	}

	const findData = (selectedZone) => {
		fetch(IP + "factura/withName/" + selectedZone)
			.then(function(response) {
				if (response.ok) {
					return response.json(); // Parsea la respuesta como JSON
				}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let bills = []
				for(let i in data.facturas){
					bills.push(Object.values(data.facturas[i]))
				}
				setBills(verifyL(bills))
			})
			.catch(function(error) {
				console.log(error);
			});

	}

	const findDataByID = (id) => {
		fetch(IP + "factura/" + id)
			.then(function(response) {
				if (response.ok) {
					return response.json(); // Parsea la respuesta como JSON
				}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let bills = []
				for(let i in data.facturas){
					bills.push(Object.values(data.facturas[i]))
				}
				setBills(verifyL(bills))
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	const findDataByClientName = (clientName) => {
		fetch(IP + "factura/withClientName/" + clientName)
			.then(function(response) {
				if (response.ok) {
					return response.json(); // Parsea la respuesta como JSON
				}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let bills = []
				for(let i in data.facturas){
					bills.push(Object.values(data.facturas[i]))
				}
				setBills(verifyL(bills))
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	const exportZone = () => {

		const dataContent = {
			"zone": selectedNameZone,
			"bills": bills
		}

		ReactDOM.render(<ZoneReport {...dataContent} />, container);

		setTimeout(function() {
			const printWindow = window.open('', '_blank');

			printWindow.document.write(container.innerHTML);

			printWindow.document.close();

			printWindow.print();
		}, 3000);
	}

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

			<div className="container-fluid">
				<Col xl="12">
					<Tab.Container defaultActiveKey="zone">
						<Card style={{padding: "20px", alignItems: "center"}}>
							<div>
								<div style={{float: "left", marginRight: "10px"}}>
									<label htmlFor="exampleFormControlInputthree" className="form-label">Start
										Date<span className="text-danger">*</span></label>
									<DatePicker
										className="form-control"
										selected={startDate}
										onChange={(date) => setStartDate(date)}
									/>
								</div>
								<div style={{float: "left"}}>
									<label htmlFor="exampleFormControlInputfour" className="form-label">End
										Date<span className="text-danger">*</span></label>
									<DatePicker
										className="form-control"
										selected={endDate}
										onChange={(date) => setEndDate(date)}
									/>
								</div>
								<div style={{float: "left", alignItems: "center", display: "flex", flexDirection: "column"}}>
									<a type="button" className="btn btn-primary light btn-sm me-1"
									   style={{marginLeft: "30px"}}
									   href={IP + "export/cuefac/" + formatDate(startDate) + "/" + formatDate(endDate)}>
										Exportar
									</a>
									<a type="button" className="btn btn-primary light btn-sm me-1"
									   style={{marginLeft: "30px", marginTop: "20px"}}
									   href={IP + "export/cuefac/"}>
										Exportar Todo
									</a>
								</div>
							</div>
						</Card>
						<Card name="badges-light" className="dz-card">
							<Card.Header className="d-flex justify-content-between flex-wrap">
								<div>
									<Card.Title>Facturas</Card.Title>
								</div>
								<div className="d-flex justify-content-between flex-wrap">
									<Nav as="ul" className="nav nav-tabs dzm-tabs" id="myTab" role="tablist">
										<Nav.Item as="li" className="nav-item" role="presentation">
											<Nav.Link as="button" type="button" eventKey="zone">Facturas por
												Zona</Nav.Link>
										</Nav.Item>
										<Nav.Item as="li" className="nav-item">
											<Nav.Link as="button" type="button" eventKey="client">Facturas por
												Cliente</Nav.Link>
										</Nav.Item>
										<Nav.Item as="li" className="nav-item">
											<Nav.Link as="button" type="button" eventKey="id">Facturas por ID</Nav.Link>
										</Nav.Item>
									</Nav>
								</div>
							</Card.Header>
							<Card.Body>
								<Tab.Content>
									<Tab.Pane eventKey="zone">
										<div className="card-header border-0 mb-0">
											<h4 className="heading mb-0">Facturas por Zona</h4>
											<div className="d-flex align-items-center cs-settiong">
												<Dropdown className='global-drop'>
													<Dropdown.Toggle as="div" className='i-false global-drop-toggle'>
														<i className="fa-solid fa-chevron-down"/>
													</Dropdown.Toggle>
													<Dropdown.Menu className='global-drop-menu'>
														<Dropdown.Item onClick={function () {
															setSelectedNameZone("Seleccionar");
														}}>Seleccionar</Dropdown.Item>
														{zones.map((data) => {
															return (
																<Dropdown.Item onClick={function () {
																	setSelectedNameZone(data[1]);
																	findData(data[1])
																}}>{data[1]}</Dropdown.Item>
															);
														})}
													</Dropdown.Menu>
												</Dropdown>
												{selectedNameZone}
												<button type="button" className="btn btn-primary p-2" style={{marginLeft: "30px"}}
														onClick={() => exportZone()}>
													Exportar {" "}
												</button>
											</div>
										</div>
									</Tab.Pane>
									<Tab.Pane eventKey="client">
										<div className="card-header border-0 mb-0">
											<h4 className="heading mb-0">Facturas por Cliente</h4>
											<div className="message-send">
													<div className="type-massage style-1">
														<div className="input-group">
															<textarea id="client_name" rows="1" className="form-control" placeholder="Cliente"></textarea>
														</div>

													</div>
													<button type="button" className="btn btn-primary p-2" onClick={() => findDataByClientName(document.getElementById("client_name").value)}>
														Buscar{" "}
													</button>
												</div>
											</div>
									</Tab.Pane>
									<Tab.Pane eventKey="id">
											<div className="card-header border-0 mb-0">
												<h4 className="heading mb-0">Facturas por ID</h4>
												<div className="message-send">
													<div className="type-massage style-1">
														<div className="input-group">
															<textarea id="bill_id" rows="1" className="form-control" placeholder="ID"></textarea>
														</div>

													</div>
													<button type="button" className="btn btn-primary p-2" onClick={() => findDataByID(document.getElementById("bill_id").value)}>
														Buscar{" "}
													</button>
												</div>
											</div>
									</Tab.Pane>
								</Tab.Content>

								<div className="card-body p-0">
									<div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
										<div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
											<table id="projects-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
												<thead>
												<tr>
													<th>Fact. ID</th>
													<th>Cliente</th>
													<th>Direccion</th>
													<th>Telefono</th>
													<th>Movil</th>
													<th>Legal</th>
													<th>Emision</th>
													<th>Vence</th>
													<th>Total</th>
												</tr>
												</thead>
												<tbody id="table">
												{ bills.map( (data) => {
													return (
														<Bill data={data}/>
													);
												})}
												</tbody>
											</table>
											<div className="d-sm-flex text-center justify-content-between align-items-center">
												<div className='dataTables_info'>

												</div>
												<div
													className="dataTables_paginate paging_simple_numbers justify-content-center"
													id="example2_paginate">
												</div>
											</div>
										</div>
									</div>
								</div>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</div>
		</>
	);

}
export default Home;