import React from 'react';
import {useNavigate} from "react-router-dom";

const Cost = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <tr>
                <td><span>{props.props.ben}</span></td>
                <td><span>{props.props.des}</span></td>
                <td><span>{props.props.fec}</span></td>
                <td><span>{props.props.val}</span></td>
                <td><span>{props.props.cja}</span></td>
            </tr>
        </>
    );

}
export default Cost;