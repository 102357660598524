import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import Cost from "./items/Cost";
import CreateCostDialog from "./creators/CreateCostDialog";
import {IP} from "./constans/Constants";
import TableItems from "./objects/TableItems";

const Costs = () => {

	const cost = useRef();

	const [data, setData] = useState([]);

	useEffect(() => {
		fetch(IP + "costs/")
			.then(function(response) {
				if (response.ok) {
					return response.json(); // Parsea la respuesta como JSON
				}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let costs = []

				for(let i in data){
					let cost = Object.values(data[i])
					costs.push(
						{
							"ben": cost[4],
							"des": cost[3],
							"fec": cost[2],
							"val": cost[6],
							"cja": cost[10],
						}
					)
				}
				setData(costs)
			})
			.catch(function(error) {
				console.log(error);
			});
	}, []);

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
			<div className="container-fluid">
				<div className='row'>
					<TableItems
						title="Costos"
						creator={cost}
						creatorDialog="Crear costo"
						tableColumns={["Beneficiario", "Descripcion", "Fecha", "Valor", "Caja"]}
						data={data}
						dataItem={ (data) => { return <Cost props={data}/> } }
					/>
				</div>
			</div>

			<CreateCostDialog
				ref={cost}
				Title="Crear costo"
			/>
		</>
	);

}
export default Costs;