import React, { useState, forwardRef, useImperativeHandle  } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Offcanvas } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import {numeroALetras} from "../utils/IntToString";
import {IP} from "../constans/Constants";

const EmployeeOffcanvas =forwardRef((props, ref) => {
    const [startDate, setStartDate] = useState(new Date());
	const [startDate2, setStartDate2] = useState(new Date());
    const [addEmploye , setAddEmploye] = useState(false);
    const navigate = useNavigate();
    useImperativeHandle(ref, () => ({
        showModal() {
            setAddEmploye(true)
        }    
    }));
    const nav = useNavigate();
    const handleSubmit=(e)=>{
        e.preventDefault();
        nav("#");
    }

    const createCost = () => {

        let code = document.getElementById("code").value
        let date = document.getElementById("date").value
        let ben = document.getElementById("ben").value
        let description = document.getElementById("description").value
        let value = document.getElementById("value").value
        let chqref = document.getElementById("chq/ref").value
        let terminal = document.getElementById("terminal").value
        let cuecod = document.getElementById("cuecod").value
        let date_ing = document.getElementById("date_ing").value
        let ciecod = document.getElementById("ciecod").value

        let data = {
            "detcod": code,
            "detfec": date,
            "detdes": description,
            "detben": ben,
            "detref": chqref,
            "detval": value,
            "cuecod": cuecod,
            "detfin": date_ing,
            "dettip": "CJA",
            "detcja": terminal,
            "ciecod": ciecod,
            "detlts": numeroALetras(value),
            "detfot": null,
        }

        fetch(IP + "costs/", {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data)})
            .then(function(response) {
                navigate("/dashboard")
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    return (
        <>
            <Offcanvas show={addEmploye} onHide={setAddEmploye} className="offcanvas-end customeoff" placement='end'>
				<div className="offcanvas-header">
					<h5 className="modal-title" id="#gridSystemModal">{props.Title}</h5>
					<button type="button" className="btn-close" 
						onClick={()=>setAddEmploye(false)}
					>
						<i className="fa-solid fa-xmark"></i>
					</button>
				</div>
				<div className="offcanvas-body">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Codigo <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="code" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Cuecod <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="cuecod" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Ciecod <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="ciecod" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Fecha <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="date" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Fecha Ingreso<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="date_ing" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Beneficiario <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="ben" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Descripcion <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="description" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Valor <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="value" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Chq/Ref <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="chq/ref" placeholder="" />
                            </div>
                            <div className="col-xl-6 mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Equipo <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" id="terminal" placeholder="" />
                            </div>

                        </div>
                        <div>
                            <button type="submit" className="btn btn-primary me-1">Aceptar</button>
                            <Link to={"#"} onClick={()=> { setAddEmploye(false); createCost()}} className="btn btn-danger light ms-1">Cancelar</Link>
                        </div>
                    </div>
				</div>
			</Offcanvas>     
        </>
    );
});

export default EmployeeOffcanvas;