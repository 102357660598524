import React, {useContext, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import { getDatabase, ref, set, push, child } from "firebase/database";
import ReactDOM from 'react-dom';
import {IP, IP_MIKROWISP} from "../constans/Constants";

import MainPagetitle from '../../../layouts/MainPagetitle';
import {Card, Col, Dropdown, Nav, Tab} from "react-bootstrap";
import Decimal from "decimal.js";
import TicketToPrint from "../print/TicketToPrint";
import {numeroALetras} from "../utils/IntToString";
import TableItems from "../objects/TableItems";
import CleanTable from "../objects/CleanTable";

const PayBill = () => {
    const [items, setItems] = useState([]);
    const [payments, setPayments] = useState([]);
    const [selectedPayment, setselectedPayment] = useState(["", "Seleccionar"]);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bill = searchParams.get('bill').split(",");
    const navigate = useNavigate();

    useEffect(() => {

        bill[7] = bill[7].replace("COMA-PARSER", ",")

        fetch(IP + "pasarela/")
            .then(function(response) {
                if (response.ok) {
                    return response.json(); // Parsea la respuesta como JSON
                }
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                let items = []
                for(let i in data.pasarelas){
                    items.push(Object.values(data.pasarelas[i]))
                }
                setPayments(items)
            })
            .catch(function(error) {
                console.log(error);
            });

        fetch(IP + "facturaItems/allByFacturaId/" + bill[0])
            .then(function(response) {
                if (response.ok) {
                    return response.json(); // Parsea la respuesta como JSON
                }
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                let items = []
                for(let i in data.items){
                    items.push(Object.values(data.items[i]))
                }
                setItems(items)
            })
            .catch(function(error) {
                console.log(error);
            });


    }, []);

    const updateSysdoc = () => {
        fetch(IP + "sysdoc/update/", {method: "GET"})
            .then(function(response) {
                if (response.ok) {
                    console.log("SYSDOC UPDATED")
                }
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    const payBill = () => {

        let billParams = {
            token: "L2JqUHdpNUdJeDgwU1dzMC9uNWtrUT09",
            idfactura: bill[0],
            idcliente: String(bill[11]).padStart(6, "0"),
            pasarela: selectedPayment[1],
            idtransaccion: document.getElementById("transacction_id").value,
            fecha: getDate(new Date().getMilliseconds()),
        }

        let docNumActivo = null
        let docFiscal = null

        if(bill[12].toLowerCase() === "factura") {
            fetch(IP + "sysdoc/get", {method: "GET"})
                .then(function(response) {
                    if (response.ok) {
                        return response.json(); // Parsea la respuesta como JSON
                    }
                    throw new Error("Error en la solicitud HTTP");
                })
                .then(function(sysdoc) {
                    fetch(IP + "aviso/" + bill[11], {method: "GET"})
                        .then(function(response) {
                            if (response.ok) {
                                return response.json(); // Parsea la respuesta como JSON
                            }
                            throw new Error("Error en la solicitud HTTP");
                        })
                        .then(function(aviso) {
                            let tipoPago = aviso.aviso.tipopago
                            if (tipoPago == 0) {
                                tipoPago = "Prepago";
                            } else {
                                tipoPago = "Postpago";
                            }
                            let cuecod = String(bill[11]).padStart(6, "0")
                            docFiscal = String(sysdoc.faccod).padStart(8, "0")
                            docNumActivo = sysdoc.docnum

                            fetch(IP + "documento/", {method: "PUT"})

                            let dateCuefac = new Date();
                            let year = dateCuefac.getFullYear();
                            let monthCuefac = ("0" + (dateCuefac.getMonth() + 1)).slice(-2);
                            let day = ("0" + dateCuefac.getDate()).slice(-2);
                            let hours = ("0" + dateCuefac.getHours()).slice(-2);
                            let minutes = ("0" + dateCuefac.getMinutes()).slice(-2);
                            let seconds = ("0" + dateCuefac.getSeconds()).slice(-2);
                            let formattedDate = `${year}-${monthCuefac}-${day} ${hours}:${minutes}:${seconds}`;

                            let cuefac = {
                                "clicel": bill[9],
                                "clidir": bill[7],
                                "clinom": bill[1],
                                "clirtn": billParams.idcliente,
                                "clitel": bill[8],
                                "cuecod": cuecod,
                                "docnum": docNumActivo,
                                "eferec": bill[4],
                                "faccja": "ADMIN-PANEL-APP",
                                "faccod": sysdoc.faccom + docFiscal,
                                "facfec": formattedDate,
                                "facfpa": selectedPayment[1],
                                "facisv": bill[4] - (bill[4] / 1.15),
                                "faclts": numeroALetras(bill[4].toString()),
                                "facmsg": "Gracias por su preferencia",
                                "facref": billParams.idtransaccion,
                                "facsub": bill[4] / 1.15,
                                "factal": "FACTURA",
                                "factip": "FACTURA",
                                "factpa": bill[4],
                                "tippag": tipoPago,
                                "totis0": "",
                                "totis1": "",
                                "totis2": "",
                                "datelimit": bill[2]
                            };

                            fetch(IP + "cuefac/save", {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(cuefac)})
                                .then(function(response) {
                                    console.log("cuefac saved")
                                })
                                .catch(function(error) {
                                    console.log(error);
                                });

                            const date = new Date();
                            const month = date.getMonth();
                            const fmt = new Intl.DateTimeFormat('es-ES', { year: 'numeric' });

                            const cuedet = {
                                "faccod": sysdoc.faccom + docFiscal,
                                "artpve": bill[4],
                                "artimp": ((bill[4] - (bill[4] * 1.15)) * -1),
                                "arttot": bill[4],
                                "cardes": items[0][2],
                                "artlin": 1,
                                "artisv": 15,
                                "artcan": 1,
                                "artdes": 0,
                                "artnom": `${month.toLocaleString('es-ES', { month: 'long' }).toUpperCase()}-${fmt.format(date)}`,
                                "artcod": String(bill[0]).padStart(8, "0")
                            };

                            fetch(IP + "cuedet/save", {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(cuedet)})
                                .then(function(response) {
                                    console.log("cuedet saved")
                                })
                                .catch(function(error) {
                                    console.log(error);
                                });

                            if (document.getElementById("print_ticket").value) {

                                let dataContent = {
                                    'cai': sysdoc.emicai,
                                    'num': sysdoc.faccom + docFiscal,
                                    'date': formattedDate,
                                    'name': bill[1],
                                    'direccion': bill[7],
                                    'rtn': bill[13],
                                    'payment': tipoPago,
                                    'tellphone': bill[9],
                                    'phone': bill[8],
                                    'items': items,
                                    'datelimit': bill[2],
                                    "isv15": (bill[4] - bill[4] / 1.15).toFixed(2),
                                    "total": parseFloat(bill[4]).toFixed(2),
                                    "totalTexto": numeroALetras(bill[4].toString()),
                                    "sysdocRange": sysdoc.vinfin,
                                    "emirtn": sysdoc.emirtn,
                                    "emicae": sysdoc.emicae,
                                }

                                const container = document.createElement('div');
                                ReactDOM.render(<TicketToPrint {...dataContent} />, container);

                                const printWindow = window.open('', '_blank');

                                printWindow.document.write(container.innerHTML);

                                printWindow.document.close();

                                setTimeout(function() {
                                    printWindow.print();
                                }, 2000);

                            }

                            if (document.getElementById("sms").value === true) {
                                sendSmsClaro(bill[9], bill[8], bill[1], parseFloat(bill[4]).toFixed(2), bill[0])
                            }

                            cambioStatusCliente(billParams)

                            updateSysdoc()
                        })
                        .catch(function(error) {
                            console.log(error);
                        });
                })
                .catch(function(error) {
                    console.log(error);
                });
        } else if (bill[12].toLowerCase() === "talonario") {
            cambioStatusCliente(billParams)
        } else {

        }

    }

    const sendSmsClaro = (cliMol, cliTel, name, total, facturaId) => {
        let url = "https://notificame.claro.com.hn/api/http/send_to_contact?api_key=x9eLXWtQdR66BwOrFSfQ44BBOI2ihKov&message="

        let numeros = ["504" + cliMol]

        let cliTels = String(cliTel).split("/")
        for (let num in cliTels) {
            numeros.push("504" + cliTels[num].replace(" ", ""))
        }

        let msg = "Estimado: " + name + ".  Hemos Registrado su pago de: " + total + " Lempiras, Correspondiente a la Factura: " + facturaId;

        for (let num in numeros) {
            fetch(url + msg + "&msisdn=" + numeros[num])
        }

    }

    const cambioStatusCliente = (billParams) => {
        const db = getDatabase();

        const audit = {
            module: "Facturacion",
            user: sessionStorage.getItem("email"),
            operation: "Pago de Factura y emision de comprobante no fiscal",
            result: "successful",
            detail: {
                cliente: bill[1],
                device: "Admin Panel",
                facturaId: bill[0],
                ticket: document.getElementById("ticket").value,
                sms: document.getElementById("sms").value,
                whatsapp: document.getElementById("whatsapp").value,
                email: document.getElementById("email").value,
            },
            regDate: getDate(new Date().getMilliseconds()),
        };

        const newPostKey = push(child(ref(db), 'Audit')).key;
        set(ref(db, 'Audit', newPostKey), audit)

        fetch(IP_MIKROWISP + "/api/v1/PaidInvoice", {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(billParams)})
            .then(function(response) {
                navigate("/dashboard")
            })
            .catch(function(error) {
                console.log(error);
            });
    }

    const getDate = (milis) => {
        var fecha = new Date(milis);
        var año = fecha.getFullYear();
        var mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que se le suma 1
        var día = fecha.getDate();

        return `${día}/${mes}/${año}`;
    }

    return (
        <>
            <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />

            <div className='container-fluid'>
                <div className='row'>

                    <CleanTable
                        title={"Detalles - Id: " + bill[0] + " - Cliente: " + bill[1]}
                        tableColumns={["Direccion", "Telefono", "Movil", "Legal", "Emision", "Vence", "Total"]}
                        data={[1]}
                        dataItem={ (data) => { return (
                            <tr>
                                <td><span>{bill[7].replace("COMA-PARSER", ",")}</span></td>
                                <td><span>{bill[8]}</span></td>
                                <td><span>{bill[9]}</span></td>
                                <td><span>{bill[3]}</span></td>
                                <td><span>{ getDate(parseInt(bill[2])) }</span></td>
                                <td><span>{ getDate(parseInt(bill[5])) }</span></td>
                                <td><span>{bill[4]}</span></td>
                            </tr>
                        ) } }
                    />

                    <CleanTable
                        title="Items"
                        tableColumns={["Item Id", "Cantidad", "Precio", "Impuesto", "Impuesto911", "Descripcion"]}
                        data={items}
                        dataItem={ (data) => { return (
                            <tr>
                                <td><span>{data[0]}</span></td>
                                <td><span>{data[5]}</span></td>
                                <td><span>{data[3]}</span></td>
                                <td><span>{data[6]}</span></td>
                                <td><span>{data[8]}</span></td>
                                <td><span style={{fontSize: "10px"}}>{data[2]}</span></td>
                            </tr>
                        ) } }
                    />

                </div>
            </div>

            <div className="container-fluid">
                <Col xl="12">
                    <Card  name="badges-light" className="dz-card">
                        <Card.Header>
                            <strong style={{fontSize: "20px", color: "black"}}>Realizar pago</strong>
                        </Card.Header>
                        <Card.Body>

                            <div className="card-header border-0 mb-0" style={{padding: "0"}}>
                                <div style={{fontSize: "16px", color: "black"}}>Formas de pago</div>
                                <div className="d-flex align-items-center cs-settiong" style={{marginTop: "10px"}}>
                                    <Dropdown className='global-drop'>
                                        <Dropdown.Toggle as="div" className='i-false global-drop-toggle'>
                                            <i className="fa-solid fa-chevron-down" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='global-drop-menu'>
                                            <Dropdown.Item onClick={function () { setselectedPayment(["", "Seleccionar"]); }}>Seleccionar</Dropdown.Item>
                                            {payments.map( (data) => {
                                                return (
                                                    <Dropdown.Item onClick={function () { setselectedPayment(data) }}>{data[1]}</Dropdown.Item>
                                                );
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {selectedPayment[1]}
                                </div>
                            </div>
                            <div className="message-send" style={{marginTop: "20px"}}>
                                <div className="type-massage style-1">
                                    <div className="input-group">
                                        <textarea id="transacction_id" rows="1" className="form-control" placeholder="Numero de Transaccion"></textarea>
                                    </div>
                                </div>
                            </div>


                            <div style={{marginTop: "50px"}}>
                                <strong style={{fontSize: "20px"}}>Imprimir</strong><div style={{ display: 'flex' }}>
                                <div className="form-check custom-checkbox mb-3 checkbox-primary" style={{ marginRight: '50px' }}>
                                    <input id="sms" type="checkbox" className="form-check-input" required="" defaultChecked={true} />
                                    <div style={{ marginTop: '4px', marginLeft: '10px' }}>Sms</div>
                                </div>
                                <div className="form-check custom-checkbox mb-3 checkbox-primary" style={{ marginRight: '50px' }}>
                                    <input id="email" type="checkbox" className="form-check-input" required="" />
                                    <div style={{ marginTop: '4px', marginLeft: '10px' }}>Email</div>
                                </div>
                                <div className="form-check custom-checkbox mb-3 checkbox-primary" style={{ marginRight: '50px' }}>
                                    <input id="whatsapp" type="checkbox" className="form-check-input" required="" defaultChecked={true} />
                                    <div style={{ marginTop: '4px', marginLeft: '10px' }}>Whatsapp</div>
                                </div>
                                <div className="form-check custom-checkbox mb-3 checkbox-primary" style={{ marginRight: '50px' }}>
                                    <input id="ticket" type="checkbox" className="form-check-input" required="" />
                                    <div style={{ marginTop: '4px', marginLeft: '10px' }}>Ticket</div>
                                </div>

                                <div className="form-check custom-checkbox mb-3 checkbox-primary" style={{ marginRight: '50px' }}>
                                    <input id="print_ticket" type="checkbox" className="form-check-input" required="" />
                                    <div style={{ marginTop: '4px', marginLeft: '10px' }}>Imprimir Ticket</div>
                                </div>
                            </div>
                            </div>

                            <div style={{marginTop: "40px", textAlign: 'right'}}>
                                <button onClick={() => payBill()} type="button" className="btn btn-primary p-2">
                                    Procesar Pago
                                </button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        </>
    );

}
export default PayBill;