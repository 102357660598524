import React, {useContext, useEffect, useRef, useState} from 'react';

import MainPagetitle from '../../layouts/MainPagetitle';
import {IP} from "./constans/Constants";
import TableItems from "./objects/TableItems";
import StoryBill from "./items/StoryBill";

const StoryBills = () => {

    const [bills, setBills] = useState([]);

    useEffect(() => {
        fetch(IP + "cuefac/all")
            .then(function(response) {
                if (response.ok) {
                    return response.json(); // Parsea la respuesta como JSON
                }
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(data) {
                let bills = []

                console.log(data)
                console.log(data[0])

                for(let i in data){
                    bills.push(data[i])
                }

                setBills( bills.sort(function(a,b){
                    return new Date(b.facfec) - new Date(a.facfec);
                }))
            })
            .catch(function(error) {
                console.log(error);
            });
    }, []);

    return (
        <>
            <MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
            <div className="container-fluid">
                <div className='row'>
                    <TableItems
                        title="Facturas Pagadas"
                        tableColumns={["ID", "Cliente", "Direccion", "Telefono", "Movil", "Emision", "Total", "Imprimir"]}
                        data={ bills }
                        dataItem={ (data) => {return (<StoryBill props={data}/>);} }
                    />
                </div>
            </div>
        </>
    );

}
export default StoryBills;