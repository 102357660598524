import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-scroll";

const CleanTable = (props) => {

    return (

        <div className="col-xl-12">
            <div className="card">
                <div className="card-body p-0">
                    <div className="table-responsive active-projects manage-client">
                        <div id="manage-tblwrapper" className="dataTables_wrapper no-footer">
                            <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                <h4 className="heading mb-0">{props.title}</h4>
                            </div>
                            <div id="manage-tblwrapper" className="dataTables_wrapper no-footer">
                                <table id="reports-tbl" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                    <tr>
                                        { props.tableColumns.map((data) => {return <th>{data}</th>}) }
                                    </tr>
                                    </thead>
                                    <tbody id="table">
                                    { props.data.map((data) => {return props.dataItem(data)}) }
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default CleanTable;