import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {numeroALetras} from "../utils/IntToString";
import ReactDOM from "react-dom";
import TicketToPrint from "../print/TicketToPrint";
import {IP} from "../constans/Constants";

const StoryBill = (props) => {
    const navigate = useNavigate();

    const print = () => {
        fetch(IP + "factura/vencimiento/" + props.props.n)
            .then(function(response) {
                if (response.ok) {
                    return response.json(); // Parsea la respuesta como JSON
                }
                throw new Error("Error en la solicitud HTTP");
            })
            .then(function(vencimiento) {
                fetch(IP + "facturaItems/allByFacturaId/" + props.props.n)
                    .then(function(response) {
                        if (response.ok) {
                            return response.json(); // Parsea la respuesta como JSON
                        }
                        throw new Error("Error en la solicitud HTTP");
                    })
                    .then(function(data) {
                        let items = []

                        for(let i in data.items){
                            items.push(Object.values(data.items[i]))
                        }

                        fetch(IP + "sysdoc/get", {method: "GET"})
                            .then(function(response) {
                                if (response.ok) {
                                    return response.json(); // Parsea la respuesta como JSON
                                }
                                throw new Error("Error en la solicitud HTTP");
                            })
                            .then(function(sysdoc) {
                                let dataContent = {
                                    'cai': sysdoc.emicai,
                                    'num': props.props.faccod,
                                    'date': props.props.facfec,
                                    'name': props.props.clinom,
                                    'direccion': props.props.clidir,
                                    'rtn': props.props.clirtn,
                                    'payment': props.props.tippag,
                                    'tellphone': props.props.clicel,
                                    'phone': props.props.clitel,
                                    'items': items,
                                    'datelimit': props.props.datelimit,
                                    "isv15": props.props.facisv,
                                    "total": parseFloat(props.props.eferec).toFixed(2),
                                    "totalTexto": numeroALetras(props.props.eferec.toString()),
                                    "sysdocRange": sysdoc.vinfin,
                                    "emirtn": sysdoc.emirtn,
                                    "emicae": sysdoc.emicae,
                                }

                                const container = document.createElement('div');
                                ReactDOM.render(<TicketToPrint {...dataContent} />, container);

                                const printWindow = window.open('', '_blank');

                                printWindow.document.write(container.innerHTML);

                                printWindow.document.close();

                                setTimeout(function() {
                                    printWindow.print();
                                }, 2000);
                            })
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            })
    }

    const getDate = (milis) => {
        var fecha = new Date(milis);
        var año = fecha.getFullYear();
        var mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que se le suma 1
        var día = fecha.getDate();

        return `${día}/${mes}/${año}`;
    }

    return (
        <>
            <tr>
                <td><span>{props.props.faccod}</span></td>
                <td><span>{props.props.clinom}</span></td>
                <td><span>{props.props.clidir}</span></td>
                <td><span>{props.props.clitel}</span></td>
                <td><span>{props.props.clicel}</span></td>
                <td><span>{ getDate(props.props.facfec) }</span></td>
                <td><span>{props.props.factpa}</span></td>
                <td><span>
                    <button type="button" className="btn btn-primary p-2"
                            onClick={() => print(props.props)}>
                        Imprimir
                    </button>
                </span></td>
            </tr>
        </>
    );

}
export default StoryBill;