import { SVGICON } from "../../constant/theme";

export const MenuList = [
    //Content
    {
        title: 'Admin Servers',
        classsChange: 'menu-title'
    },
    //Dashboard
    {
        title: 'Facturas a Pagar',
        iconStyle: SVGICON.Home,
        to: '/dashboard',
    },
    //Dashboard
    {
        title: 'Facturas Pagadas',
        iconStyle: SVGICON.Reports,
        to: '/story',
    },
    {
        title: 'Costos',
        iconStyle: SVGICON.Finance,
        to: '/costs',
    },
    {
        title: 'Clientes',
        iconStyle: SVGICON.User,
        to: '/clients',
    },
    
]