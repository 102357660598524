import React, {useContext, useEffect, useState} from 'react';

import { ThemeContext } from "../../../context/ThemeContext";
import MainPagetitle from '../../layouts/MainPagetitle';
import {Card, Col, Nav, Tab} from "react-bootstrap";
import { Dropdown } from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";
import Client from "./items/Client";
import {IP} from "./constans/Constants";
import OnlyTable from "./objects/OnlyTable";

const Clients = () => {
	const navigate = useNavigate();

	const [zones, setZones] = useState([]);
	const [selectedNameZone, setSelectedNameZone] = useState("Seleccionar");

	const [data, setData] = useState([]);
	const [allUsers, setAllusers] = useState([]);

	const verifyL = (bills) => {
		if (sessionStorage.getItem("email") == "info@comunicacioneshn.com") {
			let billsL = []
			for(let i in bills) {
				if (bills[i].facturaTipo == "Factura") {
					billsL.push(bills[i])
				}
			}
			return billsL
		} else {
			return bills
		}
	}

	function findById (id){
		fetch(IP + "usuario/" + id)
			.then(function(response) {
				if (response.ok) {
					return response.json(); // Parsea la respuesta como JSON
				}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let users = []
				let user = Object.values(data)
				users.push(
					{
						"id": user[0],
						"nombre": user[1],
						"estado": user[2],
						"correo": user[3],
						"telefono": user[4],
						"movil": user[5],
						"cedula": user[6],
						"pasarela": user[7],
						"codigo": user[8],
						"direccionPrincipal": user[9],
						"clienteZona": user[10],
						"facturaTipo": user[11],
					}
				)

				setData(verifyL(users))
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	useEffect(() => {

		fetch(IP + "zona/")
			.then(function(response) {
				if (response.ok) {
					return response.json(); // Parsea la respuesta como JSON
				}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let zones = []
				for(let i in data.zonas){
					zones.push(Object.values(data.zonas[i]))
				}
				setZones(zones)
			})
			.catch(function(error) {
				console.log(error);
			});

		fetch(IP + "usuario/")
			.then(function(response) {
				if (response.ok) {
					return response.json(); // Parsea la respuesta como JSON
				}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let users = []
				for(let i in data.usuarios){
					let user = Object.values(data.usuarios[i])
					users.push(
						{
							"id": user[0],
							"nombre": user[1],
							"estado": user[2],
							"correo": user[3],
							"telefono": user[4],
							"movil": user[5],
							"cedula": user[6],
							"pasarela": user[7],
							"codigo": user[8],
							"direccionPrincipal": user[9],
							"clienteZona": user[10],
							"facturaTipo": user[11],
						}
					)
				}

				setAllusers(verifyL(users))
			})
			.catch(function(error) {
				console.log(error);
			});


	}, []);

	const findData = (selectedZone) => {
		fetch(IP + "usuario/byZona/" + selectedZone)
			.then(function(response) {
				if (response.ok) {
					return response.json(); // Parsea la respuesta como JSON
				}
				throw new Error("Error en la solicitud HTTP");
			})
			.then(function(data) {
				let users = []
				for(let i in data.usuarios){
					let user = Object.values(data.usuarios[i])
					users.push(
						{
							"id": user[0],
							"nombre": user[1],
							"estado": user[2],
							"correo": user[3],
							"telefono": user[4],
							"movil": user[5],
							"cedula": user[6],
							"pasarela": user[7],
							"codigo": user[8],
							"direccionPrincipal": user[9],
							"clienteZona": user[10],
							"facturaTipo": user[11],
						}
					)
				}
				setData(verifyL(users))
			})
			.catch(function(error) {
				console.log(error);
			});

	}

	return (
		<>
			<MainPagetitle mainTitle="Dashboard" pageTitle="Dashboard" parentTitle="Home"  />
			<div className="container-fluid">
				<Col xl="12">
					<Tab.Container defaultActiveKey="zone" >
						<Card  name="badges-light" className="dz-card">
							<Card.Header className="d-flex justify-content-between flex-wrap">
								<div>
									<Card.Title>Clientes</Card.Title>
								</div>
								<Nav as="ul" className="nav nav-tabs dzm-tabs" id="myTab" role="tablist">
									<Nav.Item as="li" className="nav-item" role="presentation">
										<Nav.Link as="button"  type="button" eventKey="zone" onClick={() => setData([])}>Clientes por Zona</Nav.Link>
									</Nav.Item>
									<Nav.Item as="li" className="nav-item" >
										<Nav.Link as="button"  type="button" eventKey="client" onClick={() => setData([])}>Cliente por ID</Nav.Link>
									</Nav.Item>
									<Nav.Item as="li" className="nav-item" >
										<Nav.Link as="button"  type="button" eventKey="clients" onClick={() => setData(allUsers)}>Todos los Clientes</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body>
								<Tab.Content>
									<Tab.Pane eventKey="zone">
										<div className="card-header border-0 mb-0">
											<h4 className="heading mb-0">Clientes por Zona</h4>
											<div className="d-flex align-items-center cs-settiong">
												<Dropdown className='global-drop'>
													<Dropdown.Toggle as="div" className='i-false global-drop-toggle'>
														<i className="fa-solid fa-chevron-down" />
													</Dropdown.Toggle>
													<Dropdown.Menu className='global-drop-menu'>
														<Dropdown.Item onClick={function () { setSelectedNameZone("Seleccionar"); }}>Seleccionar</Dropdown.Item>
														{zones.map( (data) => {
															return (
																<Dropdown.Item onClick={function () { setSelectedNameZone(data[1]); findData(data[1]) }}>{data[1]}</Dropdown.Item>
															);
														})}
													</Dropdown.Menu>
												</Dropdown>
												{selectedNameZone}
											</div>
										</div>

									</Tab.Pane>
								</Tab.Content>

								<Tab.Content>
									<Tab.Pane eventKey="client">

										<div className="card-header border-0 mb-0">
											<h4 className="heading mb-0">Clientes por ID</h4>
											<div className="message-send">
												<div className="type-massage style-1">
													<div className="input-group">
														<textarea id="id" rows="1" className="form-control" placeholder="ID"></textarea>
													</div>

												</div>
												<button type="button" className="btn btn-primary p-2" onClick={() => findById(document.getElementById("id").value)}>
													Buscar{" "}
												</button>
											</div>
										</div>
									</Tab.Pane>
								</Tab.Content>

								<Tab.Content onclick={() => window.alert("asd")}>
									<Tab.Pane eventKey="clients">
									</Tab.Pane>
								</Tab.Content>

								<div className="table-responsive active-projects manage-client">
									<OnlyTable
										tableColumns={["Nombre", "Cedula", "Estado", "Correo", "Telefono / Movil", "Codigo", "Zona", "Tipo"]}
										data={data}
										dataItem={ (data) => { return (<Client props={data}/>); }}
									/>
								</div>

							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</div>
		</>
	);

}
export default Clients;